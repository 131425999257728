<script lang="ts">
	import Link from "../Link.svelte";
	import cake from "../../../../core/assets/icons/cake.svg?raw";
	import "@glidejs/glide/dist/css/glide.core.min.css";
	import { getGlobalSettings } from "../../getGlobalSettings.js";
	import { nbspify } from "../../../../core/utils/nbspify";
	import { voucherPathMap } from "../../../../core/schema/paths/voucherPathMap";
	import StaticPicture from "../Picture/StaticPicture.svelte";
	import voucherImage from "../../../../core/assets/voucher.jpg";

	export let buttonText = "Chci dárkový poukaz";
	export let border = false;

	const { TENANT_LOCALE } = getGlobalSettings();

	const urlSlug = `/${voucherPathMap[TENANT_LOCALE]}`;
</script>

<div
	class="border-secondary-200 flex flex-col items-center gap-10 overflow-hidden rounded-3xl px-6 py-8 min-[400px]:gap-16 sm:px-10 sm:py-12 md:px-14 md:py-16 lg:flex-row lg:gap-16 lg:p-10 lg:pl-16 lg:pr-10 xl:pl-20 xl:pr-4"
	class:border
>
	<div class="flex flex-col justify-center text-center lg:basis-[calc(50%-1.5rem)] lg:justify-start lg:text-left">
		<h2 class="decorative-headline !mx-0 !mb-6 lg:!text-left">Dárkove poukazy</h2>
		<p class="h2 mb-8 !text-lg lg:!text-xl xl:!text-2xl">{nbspify("Obdarujte své blízké poukazem na něco dobrého")}</p>
		<p>{nbspify("Nejste si jistí, jaký dort by udělal radost?")}</p>
		<p class="mb-10">{nbspify("Darujte náš dárkový poukaz.")}</p>
		<Link
			class="sm:!text-md mx-auto !rounded-full !px-8 !py-2 sm:!px-12 sm:!py-4 lg:mx-0 xl:!px-14 xl:!py-[1.125rem] xl:!text-[1.375rem]"
			href={urlSlug}
			asButton
			variant="primary"
			icon={cake}
			iconClass="w-6"
		>
			{buttonText}
		</Link>
	</div>
	<StaticPicture alt="Voucher" image={voucherImage} imgClass="rounded-lg" width={400} height={400} />
</div>
