<script lang="ts">
	import StaticPicture from "./Picture/StaticPicture.svelte";
	import cakeImgSm from "../assets/images/pavlova.jpg?w=336&webp&imagetools";
	import cakeImgMd from "../assets/images/pavlova.jpg?w=550&webp&imagetools";
	import cakeImgLg from "../assets/images/pavlova.jpg?w=794&webp&imagetools";
	import bannerHeadlineSladkaImg from "../assets/images/sladka.svg";
	import bannerHeadlineDilnaImg from "../assets/images/dilna.svg";
	import cake from "../../../core/assets/icons/cake.svg?raw";
	import Link from "./Link.svelte";
	import { nbspify } from "../../../core/utils/nbspify";
</script>

<div class="flex flex-col items-center gap-14 pb-20 pt-8 lg:flex-row lg:items-start lg:gap-8 lg:pb-28">
	<div class="z-10 flex flex-col items-center text-center lg:basis-[calc(40%-1rem)] lg:items-start lg:text-left">
		<h1 class="m-0 pt-0 xl:pt-12">
			<div class="decorative-headline lg:text-left">Cukrárna</div>
			<div class="mb-8 flex flex-col items-center gap-4 sm:mb-10 sm:gap-[1.46rem] lg:items-start">
				<StaticPicture
					class="max-w-[19rem] sm:max-w-[25rem] lg:max-w-[23rem] xl:max-w-[30rem]"
					image={bannerHeadlineSladkaImg}
					alt="Nápis Sladká"
					height={185}
					width={465}
					loading="eager"
				/>
				<StaticPicture
					class="max-w-[15rem] sm:max-w-[21rem] lg:max-w-[19rem] xl:max-w-[26rem]"
					image={bannerHeadlineDilnaImg}
					alt="Nápis dílna"
					height={185}
					width={366}
					loading="eager"
				/>
			</div>
		</h1>
		<StaticPicture
			image={cakeImgLg}
			alt="Ilustrační obrázek dortu"
			height={375}
			width={500}
			loading="eager"
			class="relative mb-4 grow lg:mb-0 lg:hidden"
			imgClass="max-w-[18.5rem] min-[360px]:max-w-[20rem] sm:max-w-[30rem]"
			sourcesets={{
				"<md": cakeImgSm,
				"<lg": cakeImgMd,
			}}
		/>
		<p>
			{nbspify(
				"Jsme rodinná cukrárna, ve které pro vás vyrábíme dorty a zákusky z těch nejlepších surovin. Buďte si jisti, že si naše sladké výtvory užijete. A to nejen po gastronomické stránce, ale i vizuální –⁠⁠⁠⁠⁠⁠⁠⁠⁠⁠⁠⁠⁠⁠⁠⁠ naše dorty vaši oslavu doslova rozzáří.",
			)}
		</p>
		<Link
			class="sm:!text-md mt-6 !rounded-full !px-8 !py-2 sm:mt-10 sm:!px-12 sm:!py-4 xl:!py-[1.125rem] xl:!text-[1.375rem] 2xl:!px-20"
			href="/dorty"
			icon={cake}
			iconClass="w-6"
			variant="primary"
			asButton
		>
			Nabídka našich dortů
		</Link>
	</div>
	<StaticPicture
		image={cakeImgLg}
		alt="Ilustrační obrázek dortu"
		height={595}
		width={794}
		loading="eager"
		class="relative hidden grow lg:block"
		imgClass="absolute top-4 left-4 scale-[1.1] max-w-full 2xl:top-6 2xl:left-6"
	/>
</div>
