<script lang="ts">
	import StaticPicture from "./Picture/StaticPicture.svelte";
	import Link from "./Link.svelte";
	import { nbspify } from "../../../core/utils/nbspify";
	import bannerImg1 from "../assets/images/b2b-banner/b2b-banner-img-1-min.png?w=288&webp&imagetools";
	import bannerImg2 from "../assets/images/b2b-banner/b2b-banner-img-2-min.png?w=288&webp&imagetools";
	import bannerImg3 from "../assets/images/b2b-banner/b2b-banner-img-3-min.png?w=288&webp&imagetools";
	import bannerImg4 from "../assets/images/b2b-banner/b2b-banner-img-4-min.png?w=288&webp&imagetools";
</script>

<div class="relative">
	<h2 class="decorative-headline !mb-8">Spolupráce</h2>
	<p class="h2 mb-8 text-center !text-lg lg:!text-xl xl:!text-2xl">
		{nbspify("Máte vlastní podnik a hodí")}<br />
		{nbspify("se vám naše dobroty?")}
	</p>
	<div class="flex flex-col items-center justify-center gap-6 lg:gap-8 xl:gap-12">
		<div class="flex flex-col gap-4 md:flex-row md:gap-6">
			<div class="flex flex-row gap-4 md:gap-6">
				<StaticPicture image={bannerImg1} alt={`Ilustrační minidezertu`} height={288} width={288} loading="lazy" />
				<StaticPicture image={bannerImg2} alt={`Ilustrační minidezertu`} height={288} width={288} loading="lazy" />
			</div>
			<div class="flex flex-row gap-4 md:gap-6">
				<StaticPicture image={bannerImg3} alt={`Ilustrační obrázek dortu`} height={288} width={288} loading="lazy" />
				<StaticPicture image={bannerImg4} alt={`Ilustrační obrázek dortu`} height={288} width={288} loading="lazy" />
			</div>
		</div>
		<Link
			class="sm:!text-md mx-auto !rounded-full !px-8 !py-2 sm:!px-12 sm:!py-4 lg:mx-0 xl:!px-14 xl:!py-[1.125rem] xl:!text-[1.375rem]"
			variant="secondary"
			asButton
			href="/chci-byt-partnerem"
		>
			Domluvit spolupráci
		</Link>
	</div>
</div>
