<script lang="ts">
	import Layout from "../components/Layout.svelte";
	import HomepageBanner from "../components/HomepageBanner.svelte";
	import Ways from "../components/Ways.svelte";
	import HowItWorks from "../components/HowItWorks.svelte";
	import WeddingsBanner from "../components/Wedding/WeddingsBanner.svelte";
	import type { HomepageProps, StepProps } from "./HomepageProps";
	import Branches from "../components/Branches.svelte";
	import RelatedProducts from "../components/Product/RelatedProducts.svelte";
	import desserts from "../../../core/assets/icons/desserts.svg?raw";
	import cake from "../../../core/assets/icons/cake-lg.svg?raw";
	import sladkaDilna from "../../../core/assets/icons/sladka-dilna.svg?raw";
	import VouchersBanner from "../components/Voucher/VouchersBanner.svelte";
	import B2bBanner from "../components/B2bBanner.svelte";
	import { getGlobalSettings } from "../getGlobalSettings";

	type $$Props = HomepageProps;

	const { products, maxProductsPerCategory } = $$restProps as $$Props;

	const { FF_B2B } = getGlobalSettings();

	const steps: StepProps[] = [
		{
			title: "Objednejte si dobroty, na které máte chuť",
			description: "Oslava nebo jen setkání s přáteli? Vyberte si z naší široké nabídky to, co vám padne do oka.",
			icon: desserts,
		},
		{
			title: "My v dílně vše s láskou napečeme podle vašich představ",
			description: "Velkou většinu stíháme napéct už do druhého dne.",
			icon: cake,
		},
		{
			title: "Připravenou krabičku si u nás osobně vyzvednete",
			description: "Přichystáme ji pro vás na vyzvednutí v naší cukrárně v Čakovicích nebo na Petřinách.",
			icon: sladkaDilna,
		},
	];
</script>

<Layout>
	<div class="container">
		<HomepageBanner />

		<section>
			<Ways />
		</section>

		<section>
			<HowItWorks {steps} />
		</section>

		<section>
			<RelatedProducts {products} {maxProductsPerCategory} />
		</section>

		<section>
			<WeddingsBanner border />
		</section>
		{#if FF_B2B === "true"}
			<section>
				<B2bBanner />
			</section>
		{/if}

		<section>
			<VouchersBanner border />
		</section>

		<section>
			<Branches />
		</section>
	</div>
</Layout>
